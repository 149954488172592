import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import storeRtk, { RootState } from '..'
import { nsaBaseUrl } from '../../../utils/API'
import { NEW_MSSS_NSA_MOCK_DATA } from '../../../utils/__Mocks__/MsssNsaNewMockData'
import { MSSS_NSA_PREDICTION_MOCK } from '../../../utils/__Mocks__/MsssNsaPredictionMock'
import {
    NEW_MSSS_NSA_MOCK,
    NSA_CATEGORIES_MOCK,
} from '../../../utils/__Mocks__/newMsssNsaMock'
import { NSA_DEPARTMENT_MOCK_DATA } from '../../../utils/__Mocks__/NsaDepartmentMock'
import { selectToken } from '../auth/selectors'
import { selectActiveUserRole } from '../user/selectors'
import {
    WaitingData,
    CategoryData,
    DataStructure,
    Category,
    NsaCategoriesList,
} from './types'
import { prepareHeaders } from '../helpers/prepareHeaders'
import { createQueryFn } from '../helpers/createQueryFn'

const isDevelopment =
    process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === 'development'

const nsaEdDemographicsURL = `${process.env.REACT_APP_SCRUBCHART_API_URL}/api/NsaEdDemographics`

export const nsaApi = createApi({
    reducerPath: 'nasApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: nsaBaseUrl,
        prepareHeaders,
    }),

    tagTypes: ['Categories', 'Departments', 'NsaInfections'],
    endpoints: (builder) => ({
        // getNsaCategories: builder.query<CategoryData, any>({
        //     query: () => `v1/NSA/categories`,
        //     providesTags: [{ type: 'Categories', id: 'LIST_CATEGORIES' }],
        //     transformResponse: (response: CategoryData): CategoryData => {
        //         if (isDevelopment) {
        //             return NEW_MSSS_NSA_MOCK
        //         }
        //         return response
        //     },
        // }),
        getNsaCategories: builder.query<NsaCategoriesList, any>({
            // query: () => `/nsa-categories`,
            // providesTags: [{ type: 'Categories', id: 'LIST_CATEGORIES' }],
            // transformResponse: (
            //     response: NsaCategoriesList
            // ): NsaCategoriesList => {
            //     if (isDevelopment) {
            //         return NSA_CATEGORIES_MOCK
            //     }
            //     return response
            // },
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${nsaEdDemographicsURL}/nsa-categories`,
                () => NSA_CATEGORIES_MOCK
            ),
        }),
        getNsaDepartments: builder.query<WaitingData, any>({
            query: () => `v1/NSA/departments`,
            providesTags: [{ type: 'Departments', id: 'LIST_Departments' }],
            transformResponse: (response: WaitingData): WaitingData => {
                if (isDevelopment) {
                    return NSA_DEPARTMENT_MOCK_DATA
                }
                return response
            },
        }),
        getNsaCategoriesPrediction: builder.query<any, any>({
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${nsaBaseUrl}/v1/NSA/NsaPredictions`,
                () => MSSS_NSA_PREDICTION_MOCK
            ),
        }),
    }),
})

export const {
    useGetNsaCategoriesQuery,
    useGetNsaDepartmentsQuery,
    useGetNsaCategoriesPredictionQuery,
} = nsaApi
